import React from 'react';
import './navbar.css';

const Navbar = () => {
  return (
        <nav className="navbar d-flex flex-column navbar-expand-lg w-100">
            <div className="grad-bar"></div>
            <div className="container-fluid">
                <div className="navbar-brand">
                  <img src={process.env.PUBLIC_URL + "/logo.png"} width={"50px"} alt=""/> Bright Minder
                </div>
            </div>
        </nav>
  )
}

export default Navbar;