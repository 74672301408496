import React from 'react';
import './ImageSlider.css';

const ImageSlider = () => {
    return (
        <div className="slideshow">
            <img
                src={`${process.env.PUBLIC_URL}/Bright Minds.png`}
                alt="Slideshow image"
                className="slideshow-image"
            />
        </div>
    );
};

export default ImageSlider;