import React from "react";
import './ComingSoon.css'
import ImageSlider from "./imageslider/ImageSlider";

const ComingSoon = () => {
    return (
        <div className="content">
            <div className="section1">
                <ImageSlider/>
            </div>
        </div>
    );
}

export default ComingSoon;