import React from "react";
import './App.css';
import Navbar from "./Components/Navbar/Navbar";
import ComingSoon from "./Pages/ComingSoon/ComingSoon";
import SideNavbar from "./Components/SocialMediaNavbar/SideNavbar";

function App() {
  return (
    <React.Fragment>
        <Navbar/>
        <ComingSoon/>
        <SideNavbar/>
    </React.Fragment>
  );
}

export default App;
