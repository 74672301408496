import React from 'react';

const MailToLink = () => {
    const recipientEmail = 'info@brightminder.co.uk';
    // const subject = 'BrightMinder.co.uk - Enquiry';
    // const emailBody =
    //     "Dear [Recipient's Name],\n" +
    //     "\n" +
    //     "I hope this email finds you well. My name is [Your Name], and I am reaching out to inquire about the nanny and governess services offered by your esteemed agency.\n" +
    //     "\n" +
    //     "We would greatly appreciate more information about your services, including the application process, caregiver profiles, and any additional details that would help us make an informed decision in selecting the right support for our family." +
    //     "\n" +
    //     "Best regards,\n" +
    //     "\n" +
    //     "[Your Full Name]\n" +
    //     "[Your Contact Information]";

    // const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(
    //     subject
    // )}&body=${encodeURIComponent(emailBody)}`;

    const mailtoLink = `mailto:${recipientEmail}`;

    return (
        <a href={mailtoLink}>
            <i className="fa fa-envelope" aria-hidden="true"></i>
        </a>
    );
};

export default MailToLink;