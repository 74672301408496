import React from "react";
import './SideNavbar.css'
import MailToButton from "../MailToButton/MailToButton";

const SideNavbar = () => {
    return (
        <nav className="social">
            <ul className="social-list">
                <li>
                    <span>
                        <i className="fab fa-whatsapp"></i>
                        <div className="whatsappQR"></div>
                    </span>
                </li>
                <li><MailToButton/></li>
            </ul>
        </nav>
    )
}

export default SideNavbar;